body {
  background-color: #fdfdfd !important;
}

#container {
  background-color: #fff;
  margin: auto;
  width: 100vw;
  max-width: 1000px;
  min-height: 100vh;
  padding: 0 5rem;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
